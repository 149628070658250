import React from 'react';
import './Footer.css'; 
import logoImg from '../assets/logo.png'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row" dir='rtl'>
        
        {/* Footer Logo */}
        <div className="footer-col">
          <div className="footer-logo">
            <img src={logoImg}  />
          </div>
        </div>

        {/* Contact Information */}
        <div className="footer-col">
          <h4>للتواصل معنا</h4>
          <div className="footer-ul">
            <p className="footer-link">شارع 68 ابو المحاسن الشاذلى العجوزة - الجيزة </p>
            <p className="footer-link">اعلى فرع اتصالات</p>
            <div className='phone'>
              <span className="footer-link" dir='ltr'>+966506552565</span> 
              <span className="footer-link">01016688751</span>                          
              <span className="footer-link"> 01110563323</span>                         
            </div>
            <p className="footer-link">alahramco2020@gmail.com</p>
          </div>
          <div></div>
        </div>

        {/* Quick Links */}
        <div className="footer-col">
          <h4>الروابط المختصرة</h4>
          <div className="footer-ul">
            <ul>
              <li className="footer-link">
                  <Link to="/">
                  الرئيسية
                  </Link>
              </li>
              <li className="footer-link">
                <Link to="/about">
                من نحن
                </Link>
              </li>
              <li className="footer-link">
                <Link to="/jobs">
                الوظائف
                </Link>
                </li>
              <li className="footer-link">
                <Link to="/partners">
                الشركاء
                </Link></li>
            </ul>
          </div>
        </div>

        {/* Social Links */}
        <div className="footer-col">
          <h4>انضم إلينا</h4>
          <div className="social-links">
            <a href="https://www.facebook.com/groups/467433135229688/?ref=share&mibextid=KtfwRi" target='_blank'><i className="fab fa-facebook footer-icon"></i></a>
            <a href="https://www.tiktok.com/@alahram.company0" target='_blank'><i className="fab fa-tiktok footer-icon"></i></a>
            <a href="#"><i className="fab fa-linkedin footer-icon"></i></a>
            <a href="#"><i className="fab fa-instagram footer-icon"></i></a>
          </div>
        
        </div>
      </div>

      {/* Divider and Copyright */}
      <hr style={{ marginBottom: '30px', color: '#C7C7C7' }} />
      <p className="styled-paragraph" style={{ marginBottom: '0', paddingBottom: '20px', fontSize: '14px' }}>
        &copy;      
        كل الحقوق محفوظة لدى الأهرام ترخيص رقم 128
      </p>
    </footer>
  );
};

export default Footer;